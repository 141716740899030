@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");

#image {
  width: 1920px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*Custom style*/

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #0087ca;
}
.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -100;
  left: 0;
  background-color: #51e09d;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.cardStyle {
  border-radius: 10px;
  margin-left: 160px;
  margin-right: 160px;
  margin-bottom: 40px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background: rgba(255, 255, 255, 0.377);
  box-shadow: 0 8px 32px 0 rgba(36, 44, 160, 0.37);
  backdrop-filter: blur(12.5px);
  -webkit-backdrop-filter: blur(12.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  font-family: "Lexend Deca", sans-serif;
}
.paragraph {
  padding-left: 50px;
  padding-right: 50px;
}
#embed {
  margin-right: 10px;
}
#title {
  color: #282c34;
  font-weight: bolder;
}
li {
  margin-bottom: 25px;
}

@media screen and (max-width: 1000px) {
  #heading {
    font-weight: 16px;
  }
  #embed {
    margin-right: 0px;
  }

  .cardStyle {
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .paragraph {
    padding-left: 10px;
    padding-right: 10px;
  }
}

#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
}

#header.header-scrolled,
#header.header-inner-pages {
  background: rgba(40, 58, 90, 0.9);
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#header .logo a {
  color: #fff;
}

#header .logo img {
  max-height: 40px;
}

.iframeDiv {
  margin-top: 16%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

table {
  margin: auto;
  width: 75vw;
}
table,
th,
td {
  font-family: "lexend deca";
  border: 1px solid black;
}

.fab {
  background-color: springgreen;
  box-shadow: 0 8px 32px 0 rgba(36, 44, 160, 0.37);
  border-radius: 30px;
  width: 100px;
  font-family: "Lexend Deca";
  height: 40px;
  border: none;
  position: fixed;
  bottom: 50px;
  right: 38px;
  transition: 0.2s;
}
.fab:hover {
  color: #fff;
  bottom: 55px;
  right: 38px;
}

/* style for gallery */
/* ======================================
Responsive Image gallery Style rules
======================================*/

@media only screen and (min-width: 768px) {
  .image-gallery {
    flex-direction: row;
  }
}

/* overlay styles */

.overlay {
  font-family: "Lexend deca";
  text-transform: capitalize;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(57, 57, 57, 0.142);
  top: 0;
  left: 0;
  transform: scale(0);
  transition: all 0.1s 0.1s ease-in-out;
  color: #fff;
  /* center overlay content */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* hover */
.image-item:hover .overlay {
  transform: scale(1);
}

@media only screen and (max-width: 600px) {
  .iframeDiv {
    margin-top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}
